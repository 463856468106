import { defineStore } from 'pinia'
import Cookies from 'js-cookie';

const banner = 'banner'

export const useBanner = defineStore(banner, {
  state: () => ({
    isCollapseBanner: false,
  }),
  actions: {
    async toggleBanner(data) {
      this.isCollapseBanner = data
      Cookies.set('isCollapseHeader', data ? 'isCollapse' : 'isOpen')
    },
  },
})

