<script setup>
import { Centrifuge, UnauthorizedError } from 'centrifuge';
import { onMounted, ref } from 'vue';
import { useEmployee } from '@/stores/employee'
import { useOrder } from '@/stores/order'

const employeeStore = useEmployee()
const orderStore = useOrder()

async function getToken() {
    const res = await fetch('/cent/get-token/');
    if (!res.ok) {
        if (res.status === 403) {
            // Return special error to not proceed with token refreshes, client will be disconnected.
            throw new UnauthorizedError();
        }
        // Any other error thrown will result into token refresh re-attempts.
        throw new Error(`Unexpected status code ${res.status}`);
    }
    const data = await res.json();
    return data;
}

async function initCentrifuge() {
  const { channels, ws_token } = await getToken()

  const centrifuge = new Centrifuge(window.centrifugoUrl, {
    token: ws_token,
  });
  
  centrifuge.on('connected', function (ctx) {
    subscription(centrifuge, channels, ctx.client)
  })
  
  centrifuge.connect();
}

async function subscription(centrifuge, channels, client) {
  const tableChannel = 'table_channel_' +  window.tableId

  const subTable = centrifuge.newSubscription(tableChannel);
  
  subTable.on('publication', function (ctx) {
    if ( ctx.data.event_class == 'table_employee_changed' ) {
      const employee = ctx.data.data.data
      employeeStore.updateEmployee(employee)
    }
    if ( ctx.data.event_class == 'order_changed' ) {
      const orderStatus = ctx.data.data
      orderStore.updateStatus(orderStatus)
    }
    if ( ctx.data.event_class == 'order_deleted' ) {
      const orderId = ctx.data.data.order_id
      orderStore.deleteOrder(orderId)
    }
    if ( ctx.data.event_class == 'order_table_changed' ) {
      const orderId = ctx.data.data.data.order_id
      const tableNumber = ctx.data.data.data.table_number
      orderStore.orderTableChanged(orderId, tableNumber)
    }
  })
  
  subTable.subscribe();
}

onMounted(async () => {
  await initCentrifuge()
})

</script>
