import CabinetModals from '../Modals'
import CabinetMenu from './CabinetMenu'
import DropdownInfoWindow from './DropdownInfoWindow'
import InstitutionsList from './InstitutionsList'
import UserInfo from './UserInfo'
import InstitutionsSearchBlock from './InstitutionsSearchBlock'
import PhotoUploader from './PhotoUploader'
import TogglerSetting from './TogglerSetting'
import CabinetCategoryCard from './CabinetCategoryCard'
import CabinetDishCard from './CabinetDishCard'
import InstitutionForm from './InstitutionForm'
import AddInstitutionButton from './AddInstitutionButton'
import EmptyInstitutionsMessage from './EmptyInstitutionsMessage'
import FinanceWarningBanner from './FinanceWarningBanner'

export default function install(Vue) {
  Vue.use(CabinetModals)
  Vue.component('CabinetMenu', CabinetMenu)
  Vue.component('DropdownInfoWindow', DropdownInfoWindow)
  Vue.component('InstitutionsList', InstitutionsList)
  Vue.component('UserInfo', UserInfo)
  Vue.component('InstitutionsSearchBlock', InstitutionsSearchBlock)
  Vue.component('PhotoUploader', PhotoUploader)
  Vue.component('TogglerSetting', TogglerSetting)
  Vue.component('CabinetCategoryCard', CabinetCategoryCard)
  Vue.component('CabinetDishCard', CabinetDishCard)
  Vue.component('InstitutionForm', InstitutionForm)
  Vue.component('AddInstitutionButton', AddInstitutionButton)
  Vue.component('EmptyInstitutionsMessage', EmptyInstitutionsMessage)
  Vue.component('FinanceWarningBanner', FinanceWarningBanner)
}
