<script>
export default {
  name: 'MenuSlot',
  props: ['isHome'],
  data() {
    return {
      show: false,
    };
  },
  mounted() {
  },
  methods: {
    close() {
      this.show = false;
    },
    trigger() {
      this.show = !this.show;
    },
    goTo(hash) {
      const el = document.getElementById(hash);
      if (this.isHome) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        this.close();
      } else {
        window.location.href = `/#${hash}`;
      }
    },
  },
  render() {
    return this.$slots.default({
      trigger: this.trigger,
      close: this.close,
      goTo: hash => this.goTo(hash),
    });
  },
};
</script>
