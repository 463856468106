import { DISH_STATUS } from '@/const'
import useGlobal from '@/composables/useGlobal'

export function getDishStatusLabel(status) {
  const { $t } = useGlobal()
  if ( status === DISH_STATUS.IN_ORDER ) return $t('Новый')
  if ( status === DISH_STATUS.AWAITING_CONFIRMATION ) return $t('Ожидает подтверждения')
  if ( status === DISH_STATUS.IN_COOKING_PROCESS ) return $t('Готовится')
  if ( status === DISH_STATUS.SERVED ) return $t('Подано')
  if ( status === DISH_STATUS.REFUSED ) return $t('Отменено')
  if ( status === DISH_STATUS.PAID ) return $t('Оплаченно')
  return '';
}