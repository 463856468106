import CabinetComponents from './Components'
import CabinetModals from './Modals'
import CabinetHeader from './CabinetHeader'
import CabinetFooter from './CabinetFooter'
import CabinetSidebar from './CabinetSidebar'


export default function install(Vue) {
  Vue.use(CabinetComponents)
  Vue.use(CabinetModals)
  Vue.component('CabinetHeader', CabinetHeader)
  Vue.component('CabinetFooter', CabinetFooter)
  Vue.component('CabinetSidebar', CabinetSidebar)

}
