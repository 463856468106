import * as Sentry from '@sentry/vue'

import {
  CHAICOM_TEST_URL,
  CHAICOM_PROD_URL,
} from '@/const'

const SENTRY_DSN = {
  [CHAICOM_TEST_URL]: 'https://9ffffb4c9aa08aff2d282dc9a0b3b407@sentry.webcase-dev.com/10',
  [CHAICOM_PROD_URL]: 'https://07c3629837d5b2bc7a94538b4000bdab@sentry.webcase-dev.com/12',
}

export default function sentryInit() {
  const dsn = SENTRY_DSN[window.location.hostname]
  if (!dsn) return

  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  })
}
