import { maska } from 'maska'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

window.toast = toast

export function install(Vue) {
  Vue
    .directive('maska', maska)
    Vue.directive('table-wrapper', {
      bind: node => {
        const tables = Array.from(node.querySelectorAll('table'));
        if (tables.length) {
          tables.forEach(el => {
            const wrapper = document.createElement('div');
            el.parentNode.insertBefore(wrapper, el);
            wrapper.appendChild(el);
            wrapper.classList.add('table-wrapper');
          });
        }
      },
    });
}



export default { install }
