import { getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'

/**
 * global vue instance
 * @returns 
 */
export default function useGlobal() {
  const instance = getCurrentInstance()
  if (!instance) return
  const { t, locale } = useI18n()
  instance.appContext.config.globalProperties.$t = t
  instance.appContext.config.globalProperties.globalLocale = locale
  return instance.appContext.config.globalProperties
}
