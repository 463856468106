import { ORDER_STATUS } from '@/const'
import useGlobal from '@/composables/useGlobal'


export function getOrderStatusLabel(status) {
  const { $t } = useGlobal()
  if ( status === ORDER_STATUS.NEW ) return $t('Новый')
  if ( status === ORDER_STATUS.NEEDS_PROCESSING ) return $t('Ожидает обработки')
  if ( status === ORDER_STATUS.IN_WORK ) return $t('В работе')
  if ( status === ORDER_STATUS.AWAITING_PAYMENT ) return $t('Ожидает оплаты')
  if ( status === ORDER_STATUS.PAID ) return $t('Оплаченно')
  if ( status === ORDER_STATUS.REFUSED ) return $t('Отменен')
  if ( status === ORDER_STATUS.DONE ) return $t('Выполнен')
  return '';
}
