import { partialRight } from 'ramda'

import {
  baseResource as superResource,
  jsonRequestMiddleware,
  bodyMiddleware,
} from '@aspectus/resource'

import {
  xCSRFMiddleware,
  multipartRequestMiddleware,
  jsonTransformer,
  defaultDataTransformer,
  raiseHttpErrorTransformer,
  superagentJsonTransformer,
} from '@aspectus/resource-commons'

import makeTemplateGetter from '@aspectus/resource-template-url-getter';

import fetcher from '@aspectus/superagent-fetch';

export const nestedMultipartRequestMiddleware = bodyMiddleware(
  body => Object.keys(body).reduce(
    (acc, x) => {
      if(Array.isArray(body[x])) {
        body[x].forEach(v => acc.append(x, v));
      } else if(typeof body[x] === 'object'  && !(body[x] instanceof File)) {
        for(let k in body[x]) {
          acc.append(`${x}.${k}`, body[x][k])
        }
      } else {
        [body[x]].forEach(v => acc.append(x, v));
      }
      return acc;
    },
    new FormData()
  )
);

export const baseResource = superResource
  .middleware(xCSRFMiddleware)
  .transform(raiseHttpErrorTransformer)

export const receiveResource = baseResource
  .transform(jsonTransformer)
  .transform(defaultDataTransformer)

export const sendResource = baseResource
  .middleware(jsonRequestMiddleware)
  .transform(jsonTransformer)
  .config('method', 'POST')

export const simpleSendResource = baseResource
  .config('method', 'POST')

export const updateResource = sendResource
  .config('method', 'PATCH')

export const replaceResource = sendResource
  .config('method', 'PUT')

export const destroyResource = baseResource
  .config('method', 'DELETE')

export const optionsResource = receiveResource
  .config('method', 'OPTIONS')

export const sendFileResource = baseResource
  .fetcher(fetcher)
  .middleware(multipartRequestMiddleware)
  .transform(superagentJsonTransformer)
  .transform(defaultDataTransformer)
  .config('method', 'POST');

export const updateWithFile = baseResource
  .transform(superagentJsonTransformer)
  .transform(defaultDataTransformer)
  .middleware(nestedMultipartRequestMiddleware)
  .config('method', 'PATCH');

export const createWithFile = baseResource
  .transform(superagentJsonTransformer)
  .transform(defaultDataTransformer)
  .middleware(nestedMultipartRequestMiddleware)
  .config('method', 'POST');

export const createResource = (template, base = baseResource) => (
  base.url(makeTemplateGetter(template))
)
export const createReceiver = partialRight(createResource, [receiveResource])
export const createSender = partialRight(createResource, [sendResource])
export const createUpdater = partialRight(createResource, [updateResource])
export const createReplacer = partialRight(createResource, [replaceResource])
export const createSimpleSender = partialRight(createResource, [simpleSendResource])
export const createFileSender = partialRight(createResource, [sendFileResource])
export const createUpdaterWithFile = partialRight(createResource, [updateWithFile])
export const createCreateWithFile = partialRight(createResource, [createWithFile])
export const createOptionsGetter = partialRight(createResource, [optionsResource])
export const createRemover = partialRight(createResource, [destroyResource])
