import { route } from '@/router/helpers'

import {
  createRoutes as PagesRoutes,
} from '@/components/Cabinet/routes'


import InitialView from '@/components/InitialView.vue'

const INITIAL_VIEW_URL = '/'
const INITIAL_VIEW_NAME = 'init'


export default function createExtentionRoutes(options) {
  return [
    route(INITIAL_VIEW_URL, InitialView, INITIAL_VIEW_NAME, {
      children: [
        ...PagesRoutes(options),
      ],
    }),
  ]
}
