import { length, } from '@vee-validate/rules'

import { Form, Field, ErrorMessage, defineRule } from './index'
import {
  url,
  required,
  email,
  confirmed,
  min,
  max,
  maxValue,
  minValue,
  emailOrPhone,
  phone,
} from './rules'

export default {
  install(app) {
    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('VeeError', ErrorMessage)
    defineRule('url', url.bind(app))
    defineRule('required', required.bind(app))
    defineRule('email', email.bind(app))
    defineRule('confirmed', confirmed.bind(app))
    defineRule('min', min.bind(app))
    defineRule('max', max.bind(app))
    defineRule('minValue', minValue.bind(app))
    defineRule('maxValue', maxValue.bind(app))
    defineRule('email_or_phone', emailOrPhone.bind(app))
    defineRule('phone', phone.bind(app))
    defineRule('length', length.bind(app))
  }
}
