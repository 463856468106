import {
  createSender,
  createReceiver,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'feedback'

const GET_INSTITUTION = prefixAPI('/institution-staff/{id}/', MODEL)
const GET_EMPLOYEE = prefixAPI('/leave-tips/{user}/', MODEL)
const PAY_TIPS = prefixAPI('/pay-tips/', MODEL)
const CREATE_REVIEW = prefixAPI('/create/', MODEL)

const CREATE_PAYMENT_URL = prefixAPI('tips/pay/')

export const getInstitutionInfo = createReceiver(GET_INSTITUTION)
export const getEmployeeInfo = createReceiver(GET_EMPLOYEE)
export const payTips = createSender(PAY_TIPS)
export const createReview = createSender(CREATE_REVIEW)

export const createPayment = createSender(CREATE_PAYMENT_URL)
