// Base elements
import AppCheckbox from './Base/AppCheckbox'
import AppRadio from './Base/AppRadio'
import AppToggle from './Base/AppToggle'
import UiLoader from './UiLoader.vue'
import UiTabs from './UiTabs'
import UiConfirm from './UiConfirm'
import BarChart from './BarChart.vue'
import CurrentWorkingHours from './CurrentWorkingHours.vue'

// Slots
import MenuSlot from './MenuSlot'
import ScrollSlot from './HashScrollSlot'
import UiAccordion from './UiAccordion'



export default function install(Vue) {
  Vue.component('AppCheckbox', AppCheckbox)
  Vue.component('AppRadio', AppRadio)
  Vue.component('AppToggle', AppToggle)
  Vue.component('MenuSlot', MenuSlot)
  Vue.component('ScrollSlot', ScrollSlot)
  Vue.component('UiAccordion', UiAccordion)
  Vue.component('UiLoader', UiLoader)
  Vue.component('UiTabs', UiTabs)
  Vue.component('UiConfirm', UiConfirm)
  Vue.component('BarChart', BarChart)
  Vue.component('CurrentWorkingHours', CurrentWorkingHours)
}
