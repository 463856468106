import { defineStore } from 'pinia'

const category = 'category'

export const useCategory = defineStore(category, {
  state: () => ({
    allCategoryHaveFop: false,
  }),
  actions: {
    changeCategoryHaveFop(val) {
      this.allCategoryHaveFop = val
    },
  },
})

