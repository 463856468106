import {
  createSender,
  // createReceiver,
  // createReplacer,
  // baseResource,
  // createUpdaterWithFile,
  // createRemover,
} from '@resource/resource';

// deleteResource
import { prefixAPI } from '@resource/api';
// import { prefixLanguage } from '@utils/urls'
// import {
//   simpleTransformer,
// } from '@resource/transformer'

const FORM_URL = prefixAPI('applications/create/');

export const sendFormResource = createSender(FORM_URL);
