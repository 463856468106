import { prefixAPI } from '@resource/api';

import {
  createReceiver,
  createSender,
  createCreateWithFile,
} from '@resource/resource';

const TWO_FACTOR_REQUEST = prefixAPI('auth/2factor/request-confirmation/{?manager-required,validate-uniqueness}')
const TWO_FACTOR_REQUEST_CONFIRM = prefixAPI('auth/2factor/confirm/')

const TOKEN_OBTAIN = prefixAPI('auth/token/obtain/')
const REFRESH_TOKEN = prefixAPI('auth/token/refresh/')

const LOGOUT = prefixAPI('auth/logout/')
const CREATE = prefixAPI('auth/user/create/')
const REGISTRATION = prefixAPI('auth/registration/')

export const make2FactorReceiver = createSender(TWO_FACTOR_REQUEST)
export const confirm2FactorReceiver = createSender(TWO_FACTOR_REQUEST_CONFIRM)

export const obtainTokenReceiver = createSender(TOKEN_OBTAIN)
export const refreshTokenReceiver = createSender(REFRESH_TOKEN)

export const logoutReceiver = createSender(LOGOUT)

export const createUserReceiver = createSender(CREATE)
export const userRegistrationReceiver = createCreateWithFile(REGISTRATION)
