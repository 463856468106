import { defineStore } from 'pinia'
import Cookies from 'js-cookie';
import { institutionsListReceiver, countLimitReceiver } from '@/services/institutions.service'

const institutions = 'institutions'

export const useInstitutions = defineStore(institutions, {
  state: () => ({
    institutionsItems: [],
    activeInstitutions: null,
    openInstitutionsList: [],
    institutionsLimit: 0,
  }),
  getters: {
    activeInstitutionsId() {
      return this.activeInstitutions?.id
    },
  },
  actions: {
    async getInstitutionsLimit() {
      try { 
        const {data: {item: limit}} = (await countLimitReceiver.execute())
        this.institutionsLimit = limit.limit
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getInstitutions() {
      try { 
        const { data: {items} } = (await institutionsListReceiver.execute())
        this.institutionsItems = items
        this.onOpenInstitutions(Cookies.get('openInstitutionId') ?? items[0].id)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async onOpenInstitutions(institutionId) {
      Cookies.set('openInstitutionId', institutionId)
      this.activeInstitutions = this.institutionsItems.find((el) => el.id == institutionId)
      this.openInstitutionsList = this.institutionsItems.filter((el) => el.id != institutionId)
    }
  },
})

