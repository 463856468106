import './public-path'

import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'
import sentryInit from './sentry'

// import 'vue-final-modal/style.css'

import './styles/index.sass'

const app = createApplication({
  createApp,
  createRouter,
  createWebHistory,
  createPinia,
})

sentryInit()

app.mount('#app')