
import {
  changeLanguage,
} from '@utils/language'
import createAppRouter from '@/router'
import i18n from './i18n'
import install from '@/install'
import Components from './components'
import Plugins from '@/plugins'

export function createApplication({
  createPinia,
  createApp,
  createRouter: createRouterInstance,
  createWebHistory,
}) {
  const app = createApp({
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
    methods: {
      changeLanguage,
    },
  })

  app.config.globalProperties.$log = console.log // you can use $log in template
  app.config.compilerOptions.delimiters = ['[[', ']]'] // use this delimiters in pug(jinja)-templates

  if (createPinia) {
    const pinia = createPinia()

    if (createRouterInstance && createWebHistory) {
      const router = createAppRouter({
        app,
        createInstance: createRouterInstance,
        createWebHistory,
        pinia,
      })
      app.use(router)
    }
    app.use(pinia)
  }

  app.config.globalProperties.$log = console.log // you can use $log in template
  app.config.compilerOptions.delimiters = ['[[', ']]'] // use this delimiters in pug(jinja)-templates

  app
    .use(i18n)
    .use(install)
    .use(Plugins)
    .use(Components)

  return app
}
