import { createVfm, ModalsContainer, VueFinalModal } from 'vue-final-modal'
import BaseInfoModal from './BaseInfoModal'
import WorkingHoursModal from './WorkingHoursModal'


export const vfm = createVfm()

export default {
  install(App) {
    App
      .use(vfm)
      .component('VueFinalModal', VueFinalModal)
      .component('ModalsContainer', ModalsContainer)
      .component('WorkingHoursModal', WorkingHoursModal)
      .component('BaseInfoModal', BaseInfoModal)
  }
}
