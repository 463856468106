import {
  createReceiver,
} from '@resource/resource'


import { prefixAPI } from '@resource/api'


const MODEL_EMPLOYEES = 'employees'

const EMPLOYEES_LIST = prefixAPI('/list/{?search}', MODEL_EMPLOYEES)


export const employeesReceiver = createReceiver(EMPLOYEES_LIST)

