import QrCodeModal from './QrCodeModal'
import MenuItemModal from './MenuItemModal'
import CategoryItemModal from './CategoryItemModal'
import ChangePhoneModal from './ChangePhoneModal'
import DishModal from './DishModal'
import AddAreaModal from './AddAreaModal'
import AddEmployeesModal from './AddEmployeesModal'
import UpdateEmployeesModal from './UpdateEmployeesModal'
import TableModal from './TableModal'
import AddNewDishOptionModal from './AddNewDishOptionModal'
import EditDishOptionModal from './EditDishOptionModal'

export default function install(Vue) {
  Vue.component('QrCodeModal', QrCodeModal)
  Vue.component('MenuItemModal', MenuItemModal)
  Vue.component('CategoryItemModal', CategoryItemModal)
  Vue.component('ChangePhoneModal', ChangePhoneModal)
  Vue.component('DishModal', DishModal)
  Vue.component('AddAreaModal', AddAreaModal)
  Vue.component('AddEmployeesModal', AddEmployeesModal)
  Vue.component('UpdateEmployeesModal', UpdateEmployeesModal)
  Vue.component('TableModal', TableModal)
  Vue.component('AddNewDishOptionModal', AddNewDishOptionModal)
  Vue.component('EditDishOptionModal', EditDishOptionModal)
}
