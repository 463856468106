
import createInitialRoutes from '@/router/routes'


export function createRoutes(options) {
  return [
    ...createInitialRoutes(options),
  ];
}
export function scrollBehavior(
  to,
  from,
  savedPosition
) {
  if (to.hash){
    return new Promise((resolve, reject) => {
        setTimeout(() => {
          document.querySelector(to.hash).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }, 300)
    })
  } else if (savedPosition) {
    return savedPosition;
  }
  return {
    top: window.pageXOffset,
    left: window.pageYOffset,
  };
}

export default function createAppRouter(options) {
  const { createInstance, createWebHistory } = options;
  const router = createInstance({
    history: createWebHistory('/'),
    scrollBehavior,
    routes: createRoutes(options),
  });


  return router;
}
