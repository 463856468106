import Ui from './UiElements'
import FormsComponents from './Forms'
import ModalComponents from './Modal'
import ControlsComponents from './Controls'
import PublicPagesComponents from './PublicPages'
import Triggers from './Triggers'
import InitialView from './InitialView'
import Cabinet from './Cabinet'

// Payment pages
import InstitutionPayment from './Payment/InstitutionPayment'
import InstitutionReview from './Payment/InstitutionReview'

export function install(Vue) {
  Vue.use(Ui)
  Vue.use(FormsComponents)
  Vue.use(ModalComponents)
  Vue.use(ControlsComponents)
  Vue.use(PublicPagesComponents)
  Vue.use(Triggers)
  Vue.use(Cabinet)
  Vue.component('InstitutionPayment', InstitutionPayment)
  Vue.component('InstitutionReview', InstitutionReview)
  Vue.component('InitialView', InitialView)
}

export default { install }
