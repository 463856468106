<template>
<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="check" clip-path="url(#clip0_4345_427)">
<path id="Vector" d="M3.82918 8.73575C3.73372 8.83176 3.60349 8.88533 3.46821 8.88533C3.33292 8.88533 3.20269 8.83176 3.10723 8.73575L0.224383 5.85243C-0.0747942 5.55325 -0.0747942 5.06812 0.224383 4.7695L0.585358 4.40844C0.884628 4.10926 1.3692 4.10926 1.66838 4.40844L3.46821 6.20836L8.3316 1.34487C8.63087 1.04569 9.11591 1.04569 9.41462 1.34487L9.7756 1.70593C10.0748 2.00511 10.0748 2.49015 9.7756 2.78886L3.82918 8.73575Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_4345_427">
<rect width="10" height="10" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
