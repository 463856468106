<template>
  <div class="preloader">
    <div class="loader">
      <svg width="100" height="100" viewBox="0 0 100 100" class="loader__circle-svg">
        <defs>
          <linearGradient id="LoaderGradient" x1="50%" y1="0%" x2="50%" y2="100%" >
            <stop offset="0%" stop-color="#F48A3F">
              <animate attributeName="stop-color" values="#F48A3F; #F48A3F" dur="4s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="100%" stop-color="#F45129">
              <animate attributeName="stop-color" values="#F45129; #F45129" dur="4s" repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
        <circle class="circle" cx="50" cy="50" r="15" fill="none"></circle>
      </svg>
    </div>
  </div>
  </template>