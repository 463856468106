<template>
  <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_227_2044)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        max-len
        d="M15.9999 0C16.7457 0 17.4281 0.410306 17.7645
      1.06109L21.5764 8.43383L30.1202 9.62609C30.8705 9.73079 31.4936 10.2457 31.7243 10.9516C31.9549 11.6575 31.7526 12.4303 31.2034 12.941L25.0512
      18.6619L26.501 26.7322C26.6309 27.4551 26.3309 28.1875 25.7267 28.623C25.1225 29.0584 24.3181 29.1218 23.6504 28.7865L15.9999 24.9454L8.34948
      28.7865C7.68174 29.1218 6.87743 29.0584 6.27319 28.623C5.66895 28.1875 5.36898 27.4551 5.49885 26.7322L6.94865 18.6619L0.796474 12.941C0.24726
      12.4303 0.0449484 11.6575 0.275634 10.9516C0.506321 10.2457 1.12941 9.73079 1.87973 9.62609L10.4235 8.43383L14.2353 1.06109C14.5718 0.410306
      15.2542 0 15.9999 0Z"
        fill="#F9E9CE"
      />
    </g>
    <defs>
      <filter id="filter0_i_227_2044" x="0.181763" y="0" width="31.6364" height="31" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_227_2044" />
      </filter>
    </defs>
  </svg>
</template>
