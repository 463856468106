<template>
  <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        max-len
        d="M15.8182 0C16.564 0 17.2463 0.410306 17.5828
      1.06109L21.3946 8.43383L29.9384 9.62609C30.6887 9.73079 31.3118 10.2457 31.5425 10.9516C31.7732 11.6575 31.5709 12.4303 31.0217 12.941L24.8695
      18.6619L26.3193 26.7322C26.4491 27.4551 26.1492 28.1875 25.5449 28.623C24.9407 29.0584 24.1364 29.1218 23.4686 28.7865L15.8182 24.9454L8.16772
      28.7865C7.49998 29.1218 6.69567 29.0584 6.09143 28.623C5.48718 28.1875 5.18722 27.4551 5.31708 26.7322L6.76688 18.6619L0.614712 12.941C0.0654975
      12.4303 -0.136814 11.6575 0.0938717 10.9516C0.324558 10.2457 0.947643 9.73079 1.69797 9.62609L10.2417 8.43383L14.0536 1.06109C14.3901 0.410306
      15.0724 0 15.8182 0Z"
        fill="#FFAB00"
      />
    </g>
    <defs>
      <filter id="filter0_b_227_2040" x="-4" y="-4" width="39.6364" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_227_2040" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_227_2040" result="shape" />
      </filter>
    </defs>
  </svg>
</template>
