import { defineStore } from 'pinia'
import { profileRetrieveReceiver } from '@api/profile.service'


const profile = 'profile'

export const useProfile = defineStore(profile, {
  state: () => ({
    profile: {},
  }),
  actions: {
    async getProfile() {
      try { 
        const { data: {item: {profile}} } = (await profileRetrieveReceiver.execute())
        this.profile = profile
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async update(data) {
      this.profile = data
    },
  },
})

