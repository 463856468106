import { useModalOpener } from '@/composables/useModalOpener'
import { usePromise } from '@/composables/usePromise'

export const useConfirm = (question) => {
  return new Promise((resolve, reject) => {
    const toPromise = value => usePromise(value)
      .then(a => resolve(a))
      .catch((e) => reject(e))
    useModalOpener({ 
      component: 'UiConfirm',
      classes: 'modal--size_xs',
      promise: toPromise,
      question,
    })  
  })
}
