import {
  createReceiver,
  createSender,
  createUpdater,
  createRemover,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const CATEGORY_CABINET = 'category'

const CATEGORY_LIST = prefixAPI('/list/{?menu}', CATEGORY_CABINET)
const CATEGORY_CREATE = prefixAPI('/create/', CATEGORY_CABINET)
const CATEGORY_ORDER = prefixAPI('/menu/{id}/order/change/', CATEGORY_CABINET)
const CATEGORY_UPDATE = prefixAPI('/{id}/update/', CATEGORY_CABINET)
const CATEGORY_DELETE = prefixAPI('/{id}/delete/', CATEGORY_CABINET)
const CATEGORY_RETRIEVE = prefixAPI('/{id}/retrieve/', CATEGORY_CABINET)

export const categoryListReceiver = createReceiver(CATEGORY_LIST)
export const categoryCreateReceiver = createSender(CATEGORY_CREATE)
export const categoryOrderReceiver = createSender(CATEGORY_ORDER)
export const categoryUpdateReceiver = createUpdater(CATEGORY_UPDATE)
export const categoryDeleteReceiver = createRemover(CATEGORY_DELETE)
export const categoryRetrieveReceiver = createReceiver(CATEGORY_RETRIEVE)
