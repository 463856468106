import InstitutionMenu from './Menu/InstitutionMenu.vue'
import CategoryCard from './Menu/CategoryCard.vue'
import DishCard from './DishCard.vue'
import ItemCounter from './ItemCounter.vue'
import CallWaiterButton from './CallWaiterButton.vue'
import PayTipsForm from './PayTipsForm.vue'
import PayTipsButton from './PayTipsButton.vue'
import WaiterInfo from './WaiterInfo.vue'
import OrderDish from './Order/OrderDish.vue'
import OrderPay from './Order/OrderPay.vue'
import PaidOrders from './Order/PaidOrders'
import VacantOrders from './Order/VacantOrders'
import DishCardInOrder from './Order/DishCardInOrder'
import CentrifugeConnector from './CentrifugeConnector'
import MainPage from './MainPage'
import InstitutionHeader from './InstitutionHeader'


export default function install(Vue) {
  Vue
    .component('InstitutionMenu', InstitutionMenu)
    .component('CategoryCard', CategoryCard)
    .component('DishCard', DishCard)
    .component('ItemCounter', ItemCounter)
    .component('CallWaiterButton', CallWaiterButton)
    .component('PayTipsForm', PayTipsForm)
    .component('PayTipsButton', PayTipsButton)
    .component('WaiterInfo', WaiterInfo)
    .component('OrderDish', OrderDish)
    .component('OrderPay', OrderPay)
    .component('VacantOrders', VacantOrders)
    .component('CentrifugeConnector', CentrifugeConnector)
    .component('PaidOrders', PaidOrders)
    .component('MainPage', MainPage)
    .component('InstitutionHeader', InstitutionHeader)
    .component('DishCardInOrder', DishCardInOrder)
    

}