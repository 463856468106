import { createI18n } from "vue-i18n"

const messages = {
  [window.language]: window.django && window.django.catalog || {},
}

export const compileMessages = () => ({
  locale: window.language,
  messages,
})

const NUMERIC = 'numeric';
const LONG = 'long';
const DATE_CONFIG = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC,
};
const SHORT_DATE_CONFIG = {
  ...DATE_CONFIG,
  year: '2-digit',
};
const TIME_CONFIG = {
  hour: NUMERIC,
  minute: NUMERIC,
};
const DATETIME_CONFIG = {
  ...DATE_CONFIG,
  ...TIME_CONFIG,
};

const datetimeFormats = {
  [window.language]: {
    shortDate: SHORT_DATE_CONFIG,
    date: DATE_CONFIG,
    time: TIME_CONFIG,
    month: { year: NUMERIC, month: LONG },
    datetime: DATETIME_CONFIG,
  },
};
const i18nOptions = {
  ...compileMessages(),
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  legacy: false,
  datetimeFormats,
}

export const i18n = createI18n(i18nOptions)


export default {
  install(app) {

    app.use(i18n)
  }
}
