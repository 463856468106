
import {
  createReceiver,
  createSender,
  createUpdater,
  createRemover,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const FOP = 'fop'
const IPAY = 'ipay'

const FOP_LIST = prefixAPI('/{institution_id}/list/', FOP)
const FOP_CREATE = prefixAPI('/{institution_id}/create/', FOP)
const FOP_DELETE = prefixAPI('/{institution_id}/{fop_id}/delete/', FOP)
const FOP_UPDATE = prefixAPI('/{institution_id}/{fop_id}/update/', FOP)

const BANK_RECEIVERS = prefixAPI('/bank-receivers/', IPAY)

export const fopListReceiver = createReceiver(FOP_LIST)
export const fopCreateReceiver = createSender(FOP_CREATE)
export const fopDeleteReceiver = createRemover(FOP_DELETE)
export const fopUpdateReceiver = createUpdater(FOP_UPDATE)

export const bankListReceiver = createReceiver(BANK_RECEIVERS)