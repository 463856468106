import { route } from '@/router/helpers'
import {
  prefixLanguage,
} from '@utils/urls'

import BasePage from '@app/Cabinet/BaseCabinetView.vue'
import MyInstitutionsPage from '@app/Cabinet/Pages/MyInstitutions.vue'
import InstitutionInfoPage from '@app/Cabinet/Pages/InstitutionInfo.vue'
import EditMenuPage from '@app/Cabinet/Pages/EditMenu.vue'
import MenuCategory from '@app/Cabinet/Pages/MenuCategory.vue'
import AllMenuPositionsPage from '@app/Cabinet/Pages/AllMenuPositions.vue'
import InstitutionEmployeesPage from '@app/Cabinet/Pages/InstitutionEmployees.vue'
import InstitutionAnalyticsPage from '@app/Cabinet/Pages/InstitutionAnalytics.vue'
import InstitutionTablesPage from '@app/Cabinet/Pages/InstitutionTables.vue'
import ProfileSettings from '@app/Cabinet/Pages/ProfileSettings.vue'
import AddInstitutionPage from '@app/Cabinet/Pages/AddInstitution.vue'
import EditInstitutionPage from '@app/Cabinet/Pages/EditInstitution.vue'

export const INITIAL_VIEW_URL = prefixLanguage('/cabinet/')

const p = l => `${INITIAL_VIEW_URL}${l}`

const INSTITUTIONS_PAGE_URL = p('institutions/')
const INSTITUTIONS_INFO_PAGE_URL = p('institutions/:id?/')
const EDIT_MENU_PAGE_URL = p('institutions/:id?/menu/:menu?/')
const MENU_CATEGORY_PAGE_URL = p('institutions/:id?/menu/:menu?/category/:category?/')
const MENU_ALL_POSITIONS_PAGE_URL = p('institutions/:id?/menu-positions/')
const EMPLOYEES_PAGE_URL = p('institutions/:id?/employees/')
const ANALYTICS_PAGE_URL = p('institutions/:id?/analytics/')
const TABLES_PAGE_URL = p('institutions/:id?/tables/')
const SETTING_PAGE_URL = p('profile/')
const ADD_INSTITUTION_PAGE_URL = p('institutions/add/')
const EDIT_INSTITUTION_PAGE_URL = p('institutions/:id?/edit/')


const BASE_PAGE_NAME = 'pages:base'
const INSTITUTIONS_PAGE_NAME = 'pages:myInstitutions'
const INSTITUTION_INFO_PAGE_NAME = 'pages:institutionsInfo'
const EDIT_MENU_PAGE_NAME = 'pages:menuEdit'
const MENU_CATEGORY_PAGE_NAME = 'pages:category'
const MENU_ALL_POSITIONS_PAGE_NAME = 'pages:menuAllPositions'
const EMPLOYEES_PAGE_NAME = 'pages:employees'
const ANALYTICS_PAGE_NAME = 'pages:analytics'
const TABLES_PAGE_NAME = 'pages:tables'
const SETTING_PAGE_NAME = 'pages:profile'
const ADD_INSTITUTION_PAGE_NAME = 'pages:addInstitution'
const EDIT_INSTITUTION_PAGE_NAME = 'pages:editInstitution'

function createRoutes(options) {
  return [
    route(INITIAL_VIEW_URL, BasePage, BASE_PAGE_NAME, {
      children: [
        route(INSTITUTIONS_PAGE_URL, MyInstitutionsPage, INSTITUTIONS_PAGE_NAME),
        route(INSTITUTIONS_INFO_PAGE_URL, InstitutionInfoPage, INSTITUTION_INFO_PAGE_NAME),
        route(EDIT_MENU_PAGE_URL, EditMenuPage, EDIT_MENU_PAGE_NAME),
        route(MENU_CATEGORY_PAGE_URL, MenuCategory, MENU_CATEGORY_PAGE_NAME),
        route(MENU_ALL_POSITIONS_PAGE_URL, AllMenuPositionsPage, MENU_ALL_POSITIONS_PAGE_NAME),
        route(EMPLOYEES_PAGE_URL, InstitutionEmployeesPage, EMPLOYEES_PAGE_NAME),
        route(ANALYTICS_PAGE_URL, InstitutionAnalyticsPage, ANALYTICS_PAGE_NAME),
        route(TABLES_PAGE_URL, InstitutionTablesPage, TABLES_PAGE_NAME),
        route(SETTING_PAGE_URL, ProfileSettings, SETTING_PAGE_NAME),
        route(ADD_INSTITUTION_PAGE_URL, AddInstitutionPage, ADD_INSTITUTION_PAGE_NAME),
        route(EDIT_INSTITUTION_PAGE_URL, EditInstitutionPage, EDIT_INSTITUTION_PAGE_NAME),
      ],
      redirect: {
        name: INSTITUTION_INFO_PAGE_NAME
      },
    })
  ]
}

export {
  INSTITUTIONS_PAGE_URL,
  BASE_PAGE_NAME,
  INSTITUTIONS_PAGE_NAME,
  INSTITUTION_INFO_PAGE_NAME,
  EDIT_MENU_PAGE_NAME,
  MENU_CATEGORY_PAGE_NAME,
  MENU_ALL_POSITIONS_PAGE_NAME,
  EMPLOYEES_PAGE_NAME,
  ANALYTICS_PAGE_NAME,
  TABLES_PAGE_NAME,
  SETTING_PAGE_NAME,
  ADD_INSTITUTION_PAGE_NAME,
  EDIT_INSTITUTION_PAGE_NAME,
  createRoutes,
}