import MenuTrigger from './MenuTrigger'
import ModalTrigger from './ModalTrigger'
import TooltipInfoTrigger from './TooltipInfoTrigger'
import AuthTrigger from './AuthTrigger'
import CopyTrigger from './CopyTrigger'

export default function install(Vue) {
  Vue.component('MenuTrigger', MenuTrigger)
  Vue.component('ModalTrigger', ModalTrigger)
  Vue.component('TooltipInfoTrigger', TooltipInfoTrigger)
  Vue.component('AuthTrigger', AuthTrigger)
  Vue.component('CopyTrigger', CopyTrigger)
}
