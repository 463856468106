import {
  createReceiver,
  createSender,
  createUpdater,
  createRemover,
} from '@resource/resource'


import { prefixAPI } from '@resource/api'


const MODEL_CABINET = 'cabinet/institutions'
const MODEL_INSTITUTIONS = 'institutions'

const INSTITUTIONS_LIST = prefixAPI('/list/', MODEL_CABINET)
const INSTITUTIONS_CREATE = prefixAPI('/create/', MODEL_CABINET)
const INSTITUTIONS_INFO = prefixAPI('/{id}/retrieve/', MODEL_CABINET)
const INSTITUTIONS_UPDATE = prefixAPI('/{id}/update/', MODEL_CABINET)

const INSTITUTIONS_EMPLOYEES = prefixAPI('/employees/list/{?institution,status*,profile_type}', MODEL_CABINET)

const INSTITUTIONS_EMPLOYEES_UPDATE = prefixAPI('/employees/{id}/update/', MODEL_INSTITUTIONS)
const INSTITUTIONS_DELETE_EMPLOYEES = prefixAPI('/employees/{id}/delete/', MODEL_INSTITUTIONS)
const INSTITUTIONS_CREATE_EMPLOYEES = prefixAPI('/employees/create/', MODEL_INSTITUTIONS)

const INSTITUTIONS_AREAS_CREATE = prefixAPI('/{id}/areas/create/', MODEL_INSTITUTIONS)
const INSTITUTIONS_AREAS_LIST = prefixAPI('/{id}/areas/list/', MODEL_INSTITUTIONS)
const INSTITUTIONS_AREAS_DELETE = prefixAPI('/{id}/areas/{area_id}/delete/', MODEL_INSTITUTIONS)

const INSTITUTIONS_TABLES_CREATE = prefixAPI('/{id}/tables/create/', MODEL_INSTITUTIONS)
const INSTITUTIONS_TABLES_LIST = prefixAPI('/{id}/tables/list/', MODEL_INSTITUTIONS)
const INSTITUTIONS_TABLES_DELETE = prefixAPI('/{id}/tables/{table_id}/delete/', MODEL_INSTITUTIONS)
const INSTITUTIONS_TABLES_UPDATE = prefixAPI('/{id}/tables/{table_id}/update/', MODEL_INSTITUTIONS)

const INSTITUTIONS_COUNT_LIMIT = prefixAPI('/count-limit/', MODEL_INSTITUTIONS)

const INSTITUTIONS_ANALYTICS = prefixAPI('/{id}/analytics/{?start,end}', MODEL_INSTITUTIONS)

const CALL_EMPLOYEE = prefixAPI('/message/send/call-employee/', MODEL_INSTITUTIONS)

const CITIES_LIST = prefixAPI('/addons/cities/', MODEL_INSTITUTIONS)
const TYPES_LIST = prefixAPI('/addons/types/', MODEL_INSTITUTIONS)


export const institutionsListReceiver = createReceiver(INSTITUTIONS_LIST)
export const createInstitutionReceiver = createSender(INSTITUTIONS_CREATE)
export const institutionInfoReceiver = createReceiver(INSTITUTIONS_INFO)
export const updateInstitutionReceiver = createUpdater(INSTITUTIONS_UPDATE)

export const employeesListReceiver = createReceiver(INSTITUTIONS_EMPLOYEES)

export const employeeUpdateReceiver = createUpdater(INSTITUTIONS_EMPLOYEES_UPDATE)
export const deleteEmployerReceiver = createRemover(INSTITUTIONS_DELETE_EMPLOYEES)
export const employeeCreateReceiver = createSender(INSTITUTIONS_CREATE_EMPLOYEES)

export const areaCreateReceiver = createSender(INSTITUTIONS_AREAS_CREATE)
export const areaListReceiver = createReceiver(INSTITUTIONS_AREAS_LIST)
export const areaDeleteReceiver = createRemover(INSTITUTIONS_AREAS_DELETE)


export const tableCreateReceiver = createSender(INSTITUTIONS_TABLES_CREATE)
export const tableListReceiver = createReceiver(INSTITUTIONS_TABLES_LIST)
export const tableDeleteReceiver = createRemover(INSTITUTIONS_TABLES_DELETE)
export const tableUpdateReceiver = createUpdater(INSTITUTIONS_TABLES_UPDATE)

export const countLimitReceiver = createReceiver(INSTITUTIONS_COUNT_LIMIT)

export const analyticsReceiver = createReceiver(INSTITUTIONS_ANALYTICS)

export const cellEmployeeReceiver = createSender(CALL_EMPLOYEE)

export const citiesListReceiver = createReceiver(CITIES_LIST)
export const typesListReceiver = createReceiver(TYPES_LIST)


