import SendForm from './SendForm'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import ChangePhoneForm from './ChangePhoneForm'

export default function install(Vue) {
  Vue.component('SendForm', SendForm)
  Vue.component('LoginForm', LoginForm)
  Vue.component('RegisterForm', RegisterForm)
  Vue.component('ChangePhoneForm', ChangePhoneForm)
}
