import {
  createReceiver,
  createSender,
  createUpdater,
  createRemover,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const DISH_CABINET = 'menu-item'
const DISH_OPTIONS = 'option'

const DISH_LIST = prefixAPI('/list/{?institution,menu,category,status,limit,offset}', DISH_CABINET)
const DISH_CREATE = prefixAPI('/create/', DISH_CABINET)
const DISH_ORDER = prefixAPI('/category/{id}/order/change/', DISH_CABINET)
const DISH_UPDATE = prefixAPI('/{id}/update/', DISH_CABINET)
const DISH_DELETE = prefixAPI('/{id}/delete/', DISH_CABINET)
const DISH_FILTERS = prefixAPI('/institution/{id}/filters/', DISH_CABINET)

const DISH_OPTIONS_LIST = prefixAPI('/{category}/list/', DISH_OPTIONS)
const DISH_OPTIONS_CREATE = prefixAPI('/{category}/create/', DISH_OPTIONS)
const DISH_OPTIONS_DELETE = prefixAPI('/{category}/{id}/delete/', DISH_OPTIONS)
const DISH_OPTIONS_UPDATE = prefixAPI('/{category}/{id}/update/', DISH_OPTIONS)

export const dishListReceiver = createReceiver(DISH_LIST)
export const dishCreateReceiver = createSender(DISH_CREATE)
export const dishOrderReceiver = createSender(DISH_ORDER)
export const dishUpdateReceiver = createUpdater(DISH_UPDATE)
export const dishDeleteReceiver = createRemover(DISH_DELETE)
export const dishInstitutionFilterReceiver = createReceiver(DISH_FILTERS)

export const dishOptionsListReceiver = createReceiver(DISH_OPTIONS_LIST)
export const dishOptionsDeleteReceiver = createRemover(DISH_OPTIONS_DELETE)
export const dishOptionsCreateReceiver = createSender(DISH_OPTIONS_CREATE)
export const dishOptionsUpdateReceiver = createUpdater(DISH_OPTIONS_UPDATE)
