import {
  createReceiver,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const TAG_URL = 'tag'

const TAG_LIST = prefixAPI('/list/', TAG_URL)


export const tagListReceiver = createReceiver(TAG_LIST)
