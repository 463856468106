<script>
import { ref, defineComponent } from 'vue'

export default defineComponent({
  name: 'MainPage',
  props: {
    vacantOrders: {
      default: () => [],
    },
  },
  setup(props, { slots }) {
    const showMenu = ref(props.vacantOrders.length == 0)

    const openMenu = () => { 
      showMenu.value = !showMenu.value
    }

    return () => slots.default({
      showMenu: showMenu.value,
      openMenu: openMenu,
    })
  },
})
</script>
