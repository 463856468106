import {
  createSender,
  createReceiver,
  createUpdaterWithFile,
} from '@resource/resource'


import { prefixAPI } from '@resource/api'


const MODEL = 'profile'

const PROFILE_RETRIEVE = prefixAPI('/retrieve/', MODEL)
const PROFILE_UPDATE = prefixAPI('/update/', MODEL)
const PROFILE_PHONE_UPDATE = prefixAPI('/phone/update/', MODEL)


export const profileRetrieveReceiver = createReceiver(PROFILE_RETRIEVE)
export const profileUpdateReceiver = createUpdaterWithFile(PROFILE_UPDATE)
export const profilePhoneUpdateReceiver = createSender(PROFILE_PHONE_UPDATE)

