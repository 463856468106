<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import useGlobal from '@/composables/useGlobal'
import { TOAST_AUTO_CLOSE_DELAY } from '@/const'

const CopyIcon = defineAsyncComponent(() => import('@/components/UiElements/i/CopyIcon.vue'));

export default defineComponent({
  name: 'CopyTrigger',
  props: {
    copyText: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    const { $t } = useGlobal()

    function handleCopy() {
      navigator.clipboard.writeText(props.copyText);
      window.toast($t('Скопировано'), {
        position: 'bottom-center',
        autoClose: TOAST_AUTO_CLOSE_DELAY,
        theme: 'colored',
        icon: CopyIcon,
      })
    }

    return () =>
      slots.default({
        copy: handleCopy,
      })
  },
})
</script>
