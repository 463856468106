import { markRaw } from 'vue'
import { useModal } from 'vue-final-modal'

import ModalContainer from '@/components/Modal/ModalContainer.vue'
import { vfm } from '@/components/Modal'

export const useModalOpener = params => {
  const { open, close } = useModal( { 
    component: markRaw(ModalContainer), 
    attrs: {
      ...params,
      modelId: params.name,
      onClose() {
        close()
      },
    },
    context: vfm, 
  })
  open()
}
