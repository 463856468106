import { useModalOpener } from '@/composables/useModalOpener'

const messageGetter = x => x.message;

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    acc[x] = messageGetter(errors[x][0])
    return acc
  }, {})
}

export function simpleValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    acc['nonFieldErrors'] = errors.message[0]
    return acc
  }, {})
}
export function nestedValidatorErrorsParser(
  errors,
  initKey = '',
  accumulator = {}
) {
  return Object.keys(errors).reduce((acc, x) => {
    const key = initKey ? `${initKey}:${x}` : x
    const currentError = errors[x]
    if ('object' !== typeof currentError) return acc
    if (Array.isArray(currentError)) {
      if (Object.prototype.hasOwnProperty.call(currentError[0], 'message')) {
        acc[key] = messageGetter(currentError[0])
        return acc
      }
      for (let i = 0, n = currentError.length; i < n; i++) {
        nestedValidatorErrorsParser(currentError[i], `${key}:${i}`, acc)
      }
      return acc
    }
    return nestedValidatorErrorsParser(currentError, key, acc)
  }, accumulator)
}


export function catchFormErrors(
  promise,
  controller,
  titleModalError,
  parser
) {
  return promise.catch(e => {
    const clientError = 400
    const serverError = 500
    const forbiddenError = 403

    if (!e.status || clientError > e.status || serverError <= e.status) {
      throw e
    }
    
    const errors = {}
    if (!parser) return Promise.reject(e)

    return e.json().then(body => {

      body.errors.forEach(error => {
        if ('request' === error.domain && error.state) {
          Object.assign(errors, error.state)
        }
      })
      updateValidator(errors, controller, parser)
      
      if (forbiddenError == e.status) {
        let deleteError = ''
        deleteError = body.errors[0].state.message
        useModalOpener({
          component: 'BaseInfoModal',
          classes: 'modal--size_md',
          modalTitle: titleModalError,
          text: deleteError
        })
      }

      return e
    })

  })
}

export function updateValidator(
  errors,
  controller,
  parser = defaultValidatorErrorsParser
) {
  controller.setFieldError?.('nonFieldErrors', '')
  const parsed = parser(errors)
  controller.setErrors?.(parsed)
}

export function submit(
  send,
  control,
  titleModalError,
  parser = defaultValidatorErrorsParser
) {
  return catchFormErrors(send, control, titleModalError, parser)
}

export function useSubmit(
  send,
  control,
  titleModalError,
  parser
) {
  return submit(send, control, titleModalError, parser)
}
