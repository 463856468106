import { defineStore } from 'pinia'

const order = 'order'

export const useOrder = defineStore(order, {
  state: () => ({
    status: null,
    deleteOrderId: null,
    changedOrderId: null,
    changedTableNumber: null,
  }),
  actions: {
    async updateStatus(data) {
      this.status = data
    },
    async deleteOrder(deleteOrder) {
      this.deleteOrderId = deleteOrder
    },
    async orderTableChanged(orderID, tableNumber) {
      this.changedOrderId = orderID
      this.changedTableNumber = tableNumber
    },
  },
})

