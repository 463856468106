import { defineStore } from 'pinia'

const employee = 'employee'

export const useEmployee = defineStore(employee, {
  state: () => ({
    employeeInfo: null,
  }),
  actions: {
    async updateEmployee(data) {
      this.employeeInfo = data
    },
  },
})

