export const CHAICOM_TEST_URL = 'chaicom.webcase-dev.com'
export const CHAICOM_PROD_URL = 'chaycom.io'

export const CHAICOM_DEVICE_UUID_COOKIE_NAME = 'CHAICOM:DEVICE_UUID_COOKIE'

export const CHAICOM_CURRENT_LOCALE = 'CHAICOM:CURRENT_LOCALE'
export const CHAICOM_ZENDESK_LOCALE = 'CHAICOM:ZENDESK_LOCALE'
export const CHAICOM_LOCALE_DIRECTION = 'CHAICOM:LOCALE_DIRECTION'

export const CHAICOM_CURRENT_ROUTE = 'CHAICOM:CURRENT_ROUTE'
export const CHAICOM_DJANGO_LANG = 'CHAICOM:DJANGO_LANG'

export const CHAICOM_ACCESS_TOKEN_STORAGE_NAME = 'CHAICOM:ACCESS_TOKEN_STORAGE'
export const CHAICOM_REFRESH_TOKEN_STORAGE_NAME = 'CHAICOM:REFRESH_TOKEN_STORAGE'


export const DEFAULT_PHONE_MASK = '+38(0##)###-##-##'

export const TOAST_AUTO_CLOSE_DELAY = 1500

export const PAYMENT_GATEWAY = 'ipay'

export const ORDER_DETAIL_SLUG = '/orders/detail/'
export const ORDER_PAY_SLUG = '/orders/pay/'
export const ORDER_REVIEW_SLUG = '/orders/leave-review/'
export const PAY_TIPS_LINK = '/orders/pay-tips/'

export const DISH_STATUS = { 
  IN_ORDER: 'in_order', 
  AWAITING_CONFIRMATION: 'awaiting_confirmation', 
  IN_COOKING_PROCESS: 'in_cooking_process', 
  SERVED: 'served',
  REFUSED: 'refused',
  PAID: 'paid',
}

export const ORDER_STATUS = { 
  NEW: 'new_', 
  NEEDS_PROCESSING: 'needs_processing',
  IN_WORK: 'in_work',
  AWAITING_PAYMENT: 'awaiting_payment', 
  PAID: 'paid', 
  REFUSED: 'refused', 
  DONE: 'done', 
}

export const INSTITUTION_FORM_TYPE = { 
  CREATE: 'create', 
  EDIT: 'edit', 
  VIEW: 'view', 
}

export const DAYS_LABEL = $t => [
  $t('Понеділок'),
  $t('Вівторок'),
  $t('Середа'),
  $t('Четвер'),
  $t('П’ятниця'),
  $t('Субота '),
  $t('Неділя'),
]

export const EMPLOYEES_STATUS = $t => [
  {
    value: 'accepted',
    label: $t('Accepted'),
  },
  {
    value: 'suspended',
    label: $t('Suspended'),
  },
]

export const MENU_GRAMAGE = $t => [
  {
    id: 'grams',
    title: $t('Грам'),
  },
  {
    id: 'milliliters',
    title: $t('Мілілітри'),
  },
]