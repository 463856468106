import {
  createReceiver,
  createSender,
  createUpdater,
  createRemover,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MENU_CABINET = 'menu'

const MENU_LIST = prefixAPI('/list/{?institution}', MENU_CABINET)
const MENU_CREATE = prefixAPI('/create/', MENU_CABINET)
const MENU_ORDER = prefixAPI('/institution/{id}/order/change/', MENU_CABINET)
const MENU_UPDATE = prefixAPI('/{id}/update/', MENU_CABINET)
const MENU_DELETE = prefixAPI('/{id}/delete/', MENU_CABINET)
const MENU_RETRIEVE = prefixAPI('/{id}/retrieve/', MENU_CABINET)

export const menuListReceiver = createReceiver(MENU_LIST)
export const menuCreateReceiver = createSender(MENU_CREATE)
export const menuOrderReceiver = createSender(MENU_ORDER)
export const menuUpdateReceiver = createUpdater(MENU_UPDATE)
export const menuDeleteReceiver = createRemover(MENU_DELETE)
export const menuRetrieveReceiver = createReceiver(MENU_RETRIEVE)
