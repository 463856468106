/* eslint-disable */
export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function isEmptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0;
}

export function getSingleParam(params, paramName) {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value) {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

function required(value) {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false || !value) {
    return this.config.globalProperties.$t('This field is required')
  }
  return true
}

function email(value) {
  if (!value || !value.length) {
    return true
  }

  const EMAILREG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!EMAILREG.test(value)) {
    return this.config.globalProperties.$t('This field must be a valid email')
  }
  return true
}

function confirmed(value, [target])  {
  if (value === target) {
    return true
  }
  return this.config.globalProperties.$t('Password did not match')
}

function min(value, params) {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (String(value).length >= Number(length)) {
    return String(value).length >= Number(length)
  } else {
    return `${this.config.globalProperties.$t('Min length')}:${length}`
  }
}

function max(value, params) {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => max(val, { length }))
  }

  if (String(value).length <= Number(length)) {
    return String(value).length <= Number(length)
  } else {
    return `${this.config.globalProperties.$t('Max length')}: ${length}`
  }
}

function maxValue(value, params)  {
  if (!value) {
    return true
  }
  const length = getSingleParam(params, 'length')
  if (Number(value) <= Number(length)) {
    return Number(value) <= Number(length)
  } else {
    return `${this.config.globalProperties.$t('Max')}: ${length}`
  }
}

function minValue(value, params)  {
  if (!value) {
    return true
  }
  const length = getSingleParam(params, 'length')
  if (Number(value) >= Number(length)) {
    return Number(value) >= Number(length)
  } else {
    return `${this.config.globalProperties.$t('Min')}: ${length}`
  }
}

function url(value) {
  if (!value || !value.length) {
    return true
  }

  const URLREG = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
  if (!URLREG.test(value)) {
    return this.config.globalProperties.$t('This field must be a valid url')
  }
  return true
}

export const MOBILEREG = /^[+][0-9]{2}[(][0-9]{3}[)][0-9]{3}-[0-9]{2}-[0-9]{2}$/im

export const EMAILREG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function emailOrPhone(value) {
  return (
    EMAILREG.test(value) ||
    MOBILEREG.test(value) ||
    this.config.globalProperties.$t('Set correct email or phone')
  )
}

function phone(value) {
  return (
    MOBILEREG.test(value) ||
    this.config.globalProperties.$t('Phone is not valid')
  )
}

export {
  url,
  required,
  email,
  confirmed,
  min,
  max,
  maxValue,
  minValue,
  emailOrPhone,
  phone,
}
